import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'state/redux/reducers/root.reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
  const store = createStore(persistedReducer, applyMiddleware(thunk))
  /* @ts-ignore */
  const persistor = persistStore(store)

  return { persistor, store }
}

export default configureStore
