import { combineReducers } from 'redux'
import authReducer from 'state/redux/reducers/auth.reducers'
import userReducer from 'state/redux/reducers/user.reducers'
import settingsReducer from 'state/redux/reducers/settings.reducer'

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  settings: settingsReducer,
})
